import React, { useRef, useState } from "react";
import { FormFeedback } from "reactstrap";
import { AuthLayout, PasswordToggle } from "shared";
import InputControl from "shared/components/InputControl";
import { useSearchParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useSignUp } from "master/hooks";
import { strongPassword } from "utils";
import moment from "moment";

import loginPasswrodGraphics from "assets/images/login-passwrod-graphics.svg";
import userAvata from "assets/images/icons/user-avatar.svg";

export const SignUp = () => {
  const [searchParams] = useSearchParams();
  const emailAddress = searchParams.get("email");
  const token = searchParams.get("token");
  const today = moment().format("YYYY-MM-DD");
  const [update, forceUpdate] = useState(0);
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate },
      validators: strongPassword,
    })
  );

  const {
    createNewUser,
    userDetails,
    passwordShown,
    onHandleChange,
    options,
    togglePassword,
  } = useSignUp();

  const handleBlur = (e) => {
    const { name } = e.target;
    validator.current.showMessageFor(name);
    if (name === "password") {
      forceUpdate(2);
    } else {
      forceUpdate(3);
    }
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      createNewUser.mutate({
        ...userDetails,
        token,
        emailAddress,
        gender: parseInt(userDetails.gender),
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <AuthLayout leftDivImage={loginPasswrodGraphics}>
      <form>
        <h2 className="mb-3">Complete Sign Up</h2>
        <div className="mb-3">
          {emailAddress && (
            <div className="auth-form-user-info d-flex justify-content-start align-items-center">
              <img src={userAvata} alt="emailAddress" />
              <span className="ms-2">{emailAddress}</span>
            </div>
          )}
        </div>
        <div className="mb-2 form-group ">
          <label className="form-label">First Name*</label>
          <InputControl
            name="firstName"
            type={"text"}
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.firstName}
            invalid={validator.current.message(
              "FirstName",
              userDetails.firstName,
              "required"
            )}
            disabled={createNewUser.isPending}
          />
          <FormFeedback>
            {validator.current.message(
              "FirstName",
              userDetails.firstName,
              "required"
            )}
          </FormFeedback>
        </div>
        <div className="mb-2 form-group ">
          <label className="form-label">Last Name</label>
          <InputControl
            name="lastName"
            type={"text"}
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.lastName}
            disabled={createNewUser.isPending}
          />
        </div>
        <div className="mb-2 form-group">
          <label className="form-label">Date of Birth</label>
          <InputControl
            name="dateOfBirth"
            type="date"
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.dateOfBirth}
            disabled={createNewUser.isPending}
            max={moment(today).format("YYYY-MM-DD")}
          />
        </div>
        <div className="mb-2 form-group">
          <label className="form-label">Phone Number</label>
          <InputControl
            name="mobile"
            type={"text"}
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.mobile}
            disabled={createNewUser.isPending}
            maxLength="10"
          />
        </div>
        <div className="mb-2 form-group">
          <label className="form-label">Gender</label>
          <select
            value={userDetails.gender}
            className={`form-select ${
              validator.current.message(
                "gender",
                userDetails.gender,
                "required"
              )
                ? "invalid"
                : ""
            }`}
            onChange={onHandleChange}
            disabled={createNewUser.isPending}
            name="gender"
          >
            {options.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="text-danger error-message-text">
            {validator.current.message(
              "gender",
              userDetails.gender,
              "required"
            )}
          </div>
        </div>
        <div className="mb-2 position-relative form-group has-icon-right change_password">
          <label className="form-label">New password*</label>
          <InputControl
            name="password"
            type={passwordShown.newPassword ? "text" : "password"}
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.password}
            invalid={validator.current.message(
              "password",
              userDetails.password,
              "required|min:8|upper|lower|digit|special"
            )}
            onBlur={handleBlur}
            disabled={createNewUser.isPending}
          />
          <PasswordToggle
            passwordShown={passwordShown?.newPassword}
            togglePassword={() => togglePassword("newPassword")}
          />
          <FormFeedback>
            {validator.current.message(
              "password",
              userDetails.password,
              "required|min:8|upper|lower|digit|special"
            )}
          </FormFeedback>
        </div>
        <div className="mb-4 position-relative form-group has-icon-right change_password">
          <label className="form-label">Confirm password*</label>
          <InputControl
            name="confirmPassword"
            type={passwordShown.confirmPassword ? "text" : "password"}
            className="form-control"
            onChange={onHandleChange}
            value={userDetails.confirmPassword}
            invalid={validator.current.message(
              "confirmPassword",
              userDetails.confirmPassword,
              `required|in:${userDetails.password}`
            )}
            disabled={createNewUser.isPending}
            onBlur={handleBlur}
          />
          <PasswordToggle
            passwordShown={passwordShown?.confirmPassword}
            togglePassword={() => togglePassword("confirmPassword")}
          />
          <FormFeedback>
            {validator.current.message(
              "confirmPassword",
              userDetails.confirmPassword,
              `required|in:${userDetails.password}`,
              {
                messages: {
                  in: "New password and confirm password must be same!",
                },
              }
            )}
          </FormFeedback>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => onSubmit(e)}
          disabled={createNewUser.isPending}
        >
          Register
        </button>
      </form>
    </AuthLayout>
  );
};
