import React from "react";
import { useNotificationBanner } from "..";
import { LoadingSpinner } from "shared";
import { NotificationIndividualItem } from "..";

export const NotificationItems = () => {
  const { notificationsBanner, notificationDetails: data } =
    useNotificationBanner({
      load: true,
      unreadOnly: false,
    });
  const { isLoading } = notificationsBanner;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="notification-listng-page">
          <ul className="notification-list">
            {data?.map((notification) => (
              <NotificationIndividualItem
                data={notification}
                key={notification.key}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
