import { useEffect } from "react";
import { getCourse, GET_COURSE_BY_ID } from "..";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getSelectedProgram } from "utils";

export const useCourse = ({ type = null }) => {
  const navigate = useNavigate();

  let selectedProgram = getSelectedProgram();
  let { studentBatchId } = !!selectedProgram && selectedProgram;

  let selectedCourseGroup = sessionStorage.getItem("courseGroup")
    ? JSON.parse(sessionStorage.getItem("courseGroup"))
    : null;
  let { id: courseGroupId, name } =
    !!selectedCourseGroup && selectedCourseGroup;

  let courseListStorage = sessionStorage.getItem("courseList")
    ? JSON.parse(sessionStorage.getItem("courseList"))
    : null;
  let { hasCourseGroup, displayName } =
    !!courseListStorage && courseListStorage;

  useEffect(() => {
    if (
      (hasCourseGroup && !courseGroupId) ||
      !studentBatchId ||
      type === null
    ) {
      navigate("/program");
    }
  }, [hasCourseGroup]);

  const courseList = useQuery({
    queryKey: [GET_COURSE_BY_ID, studentBatchId, type, courseGroupId],
    queryFn: () => getCourse({ studentBatchId, type, courseGroupId }),
    enabled: !!studentBatchId && (!!type || type === 0),
  });

  return {
    courseList,
    courseGroupId,
    name,
    courseType: type,
    hasCourseGroup,
    displayName,
  };
};
