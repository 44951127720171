import { useQuery } from "@tanstack/react-query";
import {
  GET_PROGRAM_SELECTLIST_FOR_CERTIFICATE,
  getProgramSelectListForCertificate,
} from "../api";

export const useCertificateProgramSelectListQuery = ({ load = false }) => {
  return useQuery({
    queryKey: [GET_PROGRAM_SELECTLIST_FOR_CERTIFICATE],
    queryFn: () => getProgramSelectListForCertificate(),
    enabled: load,
  });
};
