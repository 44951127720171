import { useQuery } from "@tanstack/react-query";
import { assessmentResult, GET_ASSESSMENT_RESULT } from "..";

export const useAssessmentResultQuery = ({ assessmentMasterId }) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_RESULT, assessmentMasterId],
    queryFn: () => assessmentResult(assessmentMasterId),
    enabled: !!assessmentMasterId,
  });
};
