import React, { useContext, useEffect, createContext } from "react";
import { useImmer } from "use-immer";
import { getCookie } from "utils";

const AppContext = createContext();

export const AppScope = (props) => {
  const initial = {
    isAuthenticated: false,
    user: "",
    token: "",
    registrationId: "",
    error: null,
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;

    const validEnvironments = ["production", "gtec"];
    const currentEnvironment = process.env.REACT_APP_ENVIORNMENT_KEY;

    if (validEnvironments.includes(currentEnvironment)) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = true;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "p7i9hojl6o");
    }
  }, []);

  const localData = JSON.parse(getCookie("_stu_user_data"));
  const stuIsRemember = localStorage.getItem("_stu_is_remember")
    ? JSON.parse(localStorage.getItem("_stu_is_remember"))
    : false;

  const [AppState, setAppState] = useImmer(localData || initial);
  const [isRemember, setIsRemember] = useImmer(stuIsRemember);

  useEffect(() => {
    localStorage.setItem(
      "_stu_is_remember",
      JSON.stringify(isRemember ? isRemember : false)
    );
  }, [isRemember]);

  return (
    <AppContext.Provider
      value={{
        AppState,
        setAppState,
        isRemember,
        setIsRemember,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppScope = () => useContext(AppContext);
