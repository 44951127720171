import React, { forwardRef } from "react";
import InputControl from "shared/components/InputControl";
import activationCodeSvg from "assets/images/activationCode.svg";
import ArrowRightIconWhite from "assets/images/icons/right-arrow.svg";
import "../styles/activationCode.styles.css";

export const ActivationCodeModalBody = forwardRef((props, ref) => {
  const {
    activationCodes,
    setActivationCode,
    addActivationCode,
    onActivateCode,
    showError,
    setShowError,
  } = props;

  const errorMessage = ref.current.message(
    "Activation Code",
    activationCodes,
    "required"
  );

  const handleChange = (e) => {
    setActivationCode((draft) => {
      draft = e.target.value;
      return draft;
    });
    setShowError((draft) => {
      draft = "";
      return draft;
    });
  };

  return (
    <div className="activation_code">
      <img src={activationCodeSvg} alt="activation-code-svg" />
      <p className="activation_code_title">Add a new course to your profile</p>
      <p className="activation_code_message pt-3">
        Unlock access to new programs or courses by entering the code provided
        to you.{" "}
      </p>
      <div className="d-flex justify-content-between pt-3 gap-1">
        <div>
          <InputControl
            type="text"
            id="activation-code"
            className="form-control activation_form_control"
            name="Activation Code"
            placeholder="Activation Code"
            value={activationCodes}
            onChange={handleChange}
            invalid={showError ? true : errorMessage}
          />
        </div>
        <button
          className="btn btn-nergy-primary btn-activation_code"
          onClick={onActivateCode}
          disabled={addActivationCode?.isPending}
        >
          <span className="btn-txt">Activate</span>
          <span className="btn-arrow">
            <img src={ArrowRightIconWhite} alt="right-arrow-icon-white" />
          </span>
        </button>
      </div>
      <p className="activation_code_error error-message-text">
        {showError
          ? "Incorrect activation code. Please contact support for more details"
          : errorMessage}
      </p>
    </div>
  );
});
