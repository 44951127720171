import React from "react";
import {
  CertificateItem,
  CertificatePreviewModal,
  useCertificate,
  useCertificateProgramSelectListQuery,
} from "..";
import {
  ErrorComponent,
  LoadingSpinner,
  ModalLayout,
  PageLayout,
} from "shared";
import ErrorImg from "assets/images/error-no-certificate-found-graphics.svg";
import { getSelectedProgram } from "utils";
import shockingImg from "assets/images/shocking-sale.svg";
import { useSupportTicket } from "master";

export const CertificateList = ({ isAllCertificate }) => {
  const data = getSelectedProgram();
  const certificateProgramName = data?.name;
  const studentBatchId = data?.studentBatchId;
  const {
    selectedProgram,
    setSelectedProgram,
    downloadPdf,
    studentBatchCertificate,
    onCloseModal,
    modalState,
    onOpenModal,
    setClickedTimes,
  } = useCertificate({
    load: true,
    studentBatchId,
    isAllCertificate,
  });
  const { data: program } = useCertificateProgramSelectListQuery({
    load: true,
  });

  const hasCertificate = studentBatchCertificate?.data?.length > 0;

  const titleEndComponent = (
    <>
      {isAllCertificate && (
        <div className="select-dropdown-group">
          <select
            className="custom-select-drop"
            value={selectedProgram}
            onChange={(e) =>
              setSelectedProgram((draft) => {
                draft = e.target.value;
                return draft;
              })
            }
          >
            <option value="" selected>
              All Programs
            </option>
            {program &&
              program?.map((item) => (
                <option value={item.id} key={`program-${item.id}`}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      )}
    </>
  );
  const stdDetails = getSelectedProgram();
  const { supportTicketsForCertificate } = useSupportTicket();
  const RaiseTicket = () => {
    supportTicketsForCertificate.mutate({
      message: "Certificate not generated",
      studentBatchId: stdDetails?.studentBatchId || 0,
    });
  };

  const goToPreviousPage = () => {
    setClickedTimes((draft) => {
      return draft + 1;
    });
  };

  return (
    <PageLayout
      breadcrumb={
        !isAllCertificate
          ? [
              {
                label: `${
                  certificateProgramName ? certificateProgramName : ""
                }`,
                location: "/program",
              },
              { label: "Certificates" },
            ]
          : [{ label: "Certificates" }]
      }
      titleEndComponent={titleEndComponent}
      title={hasCertificate ? "Certificates" : ""}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-grid-items page-grid-items--box grid-item-certificate">
            {studentBatchCertificate?.isLoading ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {hasCertificate ? (
                  studentBatchCertificate?.data
                    ?.sort((a, b) => b?.isGenerated - a?.isGenerated)
                    ?.map((item) => (
                      <CertificateItem
                        item={item}
                        key={`ProgramName-${item.id}`}
                        onOpenModal={onOpenModal}
                        certificate={isAllCertificate}
                        certificateProgramName={certificateProgramName}
                      />
                    ))
                ) : (
                  <ErrorComponent
                    title="No Certificate Found!"
                    message="There is no certificate assigned for you at the moment."
                    image={ErrorImg}
                    btnLabel="Check Again"
                    onClick={goToPreviousPage}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {modalState.isOpen && modalState.actionFrom === "openModal" && (
        <CertificatePreviewModal
          onCloseModal={onCloseModal}
          downloadPdf={downloadPdf}
          modalState={modalState}
        />
      )}
      {modalState.isOpen &&
        modalState.actionFrom === "NoCertificateAssigned" && (
          <ModalLayout
            isOpen={
              modalState.isOpen &&
              modalState.actionFrom === "NoCertificateAssigned"
            }
            title={"Still no certificate assigned?"}
            message={modalState?.info?.message}
            onConfirm={RaiseTicket}
            onCancel={onCloseModal}
            icon={shockingImg}
            confirmButtonLabel={"Raise A Ticket"}
            iconClass={"modal-icon-big"}
          />
        )}
    </PageLayout>
  );
};
