import { useImmer } from "use-immer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationBannerQuery } from ".";
import { GET_FILTERED_NOTIFICATIONS, createOpenedNotification } from "..";
import { errorFormatter } from "utils";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export const useNotificationBanner = ({ unreadOnly = false, load = false }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [notificationDetails, setNotificationDetails] = useImmer(null);
  const [closeNotification, setCloseNotification] = useImmer(true);
  const { notificationsBanner } = useNotificationBannerQuery({
    unreadOnly,
    load,
  });

  const dateFormatted = (localCreatedDate) =>
    moment(localCreatedDate, "DD-MMM-YYYY HH:mm:ss A").format(
      "DD MMM, YYYY [at] hh:mm A"
    );

  const createViewedNotification = useMutation({
    mutationFn: createOpenedNotification,
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(GET_FILTERED_NOTIFICATIONS);
      navigate("/notifications");
    },
  });

  const viewAllNotification = async () => {
    let unReadMessage = [];
    const newData = notificationsBanner?.data?.filter(
      (notification) => notification.id
    );
    if (newData && newData.length > 0) {
      newData?.forEach((item) => {
        unReadMessage.push(item.id);
      });
      await createViewedNotification.mutate(unReadMessage);
    } else {
      navigate("/notifications");
    }
  };
  useEffect(() => {
    if (notificationsBanner?.data) {
      setNotificationDetails((draft) => {
        notificationsBanner?.data.map((item) => ({ ...item, key: uuidv4() }));
        draft = notificationsBanner?.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        return draft;
      });
    }
  }, [notificationsBanner?.data]);

  return {
    notificationsBanner,
    closeNotification,
    setCloseNotification,
    dateFormatted,
    createViewedNotification,
    viewAllNotification,
    notificationDetails,
  };
};
