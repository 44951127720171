import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import parse from "html-react-parser";
import questionIcon from "assets/images/icons/question-icon-thick-black.svg";
import clockIcon from "assets/images/icons/clock-icon-black.svg";
import boardIcon from "assets/images/icons/board-with-checks-icon-black.svg";

export const InstructionsModal = ({
  isOpen,
  onCancel,
  onSubmit,
  data,
  actionFrom,
  ...rest
}) => {
  const { name, noOfQuestions, maxTime, maxAttempt, instructions } = data;

  const isFromAssessmentList = actionFrom === "assessmentList";

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      scrollable={true}
      className="common-modal modal__instructions"
      {...rest}
    >
      <ModalHeader toggle={onCancel}>Instructions</ModalHeader>
      <ModalBody>
        <div className="modal-content-block">
          <span className="block-title mb-1">{name}</span>
          <span className="block-title">
            Read the below instructions carefully before you start the exam:
          </span>
          <div className="instruction-points-group">
            <div className="point-item">
              <div className="point-icon">
                <img src={questionIcon} alt="" />
              </div>
              <div className="point-label">{`Total Questions: ${noOfQuestions}`}</div>
            </div>
            <div className="point-item">
              <div className="point-icon">
                <img src={clockIcon} alt="" />
              </div>
              <div className="point-label">{`Total Time : ${maxTime} Min`}</div>
            </div>
            <div className="point-item">
              <div className="point-icon">
                <img src={boardIcon} alt="" />
              </div>
              <div className="point-label">{`Max Attempts: ${maxAttempt}`}</div>
            </div>
          </div>
          <div className="instructions-content-inner-block">
            {!!instructions && parse(instructions)}
          </div>
          {isFromAssessmentList && (
            <div className="assessment-modal-action-btns text-end pt-3">
              <button onClick={onSubmit} className="btn btn-blue">
                Proceed
              </button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
