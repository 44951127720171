import React, { useEffect, useState } from "react";
import {
  AssessmentStatusRow,
  AttemptItem,
  useTimeout,
  AssessmentSpinnerElement,
  ScheduledDateView,
  GET_ASSESSMENT_LIST,
} from "..";
import questionIcon from "assets/images/icons/quesion-mark-grey.svg";
import timerIcon from "assets/images/icons/timer-icon-dark-grey.svg";
import greenCheck from "assets/images/icons/green-check-filled.svg";
import closeIcon from "assets/images/icons/close-filled-red.svg";
import sandClockIcon from "assets/images/icons/sand-timer-bown-icon.svg";
import notAttemptedIcon from "assets/images/icons/not-attempted.svg";
import { useQueryClient } from "@tanstack/react-query";

export const AssessmentItem = ({
  assessmentData,
  onStartAssessment,
  onScheduleAssessment,
  isFetching,
  studentBatchId,
}) => {
  const {
    name,
    noOfQuestions,
    maxTime,
    remainingAttempt,
    maxAttempt,
    attemptStatus,
    activeSchedule,
    assessmentId,
    attempts,
    slotId,
    attemptId,
    instructions,
    currentSystemTime,
    startAt,
    endAt,
    scheduleMasterId,
  } = assessmentData;
  const queryClient = useQueryClient();

  const [isTimeUp, setIsTimeUp] = useState(false);

  const { timer } = useTimeout({
    currentTime: currentSystemTime,
    startTime: startAt,
  });
  const { timer: endTimer } = useTimeout({
    startTime: endAt,
    currentTime: currentSystemTime,
  });

  const isFetchingData = isFetching;
  const isSchedule = attemptStatus === 0;
  const isScheduled = attemptStatus === 1;
  const isStart = attemptStatus === 2;
  const isContinue = attemptStatus === 3;
  const isNotAttempted = attemptStatus === 4;
  const isPassed = attemptStatus === 5;
  const isFailed = attemptStatus === 6;
  const isAwaitingResult = attemptStatus === 7;

  const classNames = () => {
    switch (attemptStatus) {
      case 3:
        return "assessment-card-continue";
      case 4:
        return "assessment-card-not-attempted";
      case 5:
        return "assessment-card-passed";
      case 6:
        return "assessment-card-failed";
      case 7:
        return "assessment-card-waiting";
      default:
        return "assessment-card-default";
    }
  };

  useEffect(() => {
    if (timer <= 0 && isScheduled) {
      setIsTimeUp(true);
    } else {
      setIsTimeUp(false);
    }
  }, [timer]);

  useEffect(() => {
    if (endTimer <= 0 && (isScheduled || isStart || isContinue)) {
      queryClient.invalidateQueries([GET_ASSESSMENT_LIST, studentBatchId]);
    }
  }, [endTimer]);

  return (
    <li className={`assessment-list-item ${classNames()}`}>
      {isPassed && (
        <AssessmentStatusRow icon={greenCheck} label="Assessment Passed" />
      )}
      {isFailed && <AssessmentStatusRow icon={closeIcon} label="Failed" />}
      {isAwaitingResult && (
        <AssessmentStatusRow icon={sandClockIcon} label="Result Waiting" />
      )}
      {isNotAttempted && (
        <AssessmentStatusRow icon={notAttemptedIcon} label="Not Attempted" />
      )}

      <div className="assessment-info-card">
        <div className="basic-info-left-block">
          <h2>{name}</h2>
          <div className="basic-info-row">
            <div className="info-badge-group">
              <div className="info-item">
                <img src={questionIcon} alt="" />
                <span>{`${noOfQuestions} Questions`}</span>
              </div>
              <div className="info-item">
                <img src={timerIcon} alt="" />
                <span>{`${maxTime} Min`}</span>
              </div>
            </div>
            <div className="info-badge">
              <span>{`You have ${remainingAttempt}/${maxAttempt} Attempts left`}</span>
            </div>
          </div>
        </div>
        {(isSchedule || isScheduled || isStart || isContinue) && (
          <div className="basic-info-right-block">
            {isFetchingData ? (
              <AssessmentSpinnerElement className="assessment-sm-loader" />
            ) : (
              <>
                {isSchedule && (
                  <button
                    onClick={() =>
                      onScheduleAssessment({ assessmentId, scheduleMasterId })
                    }
                    className="btn btn-blue"
                  >
                    Schedule Assessment
                  </button>
                )}
                {isScheduled && !isTimeUp && (
                  <ScheduledDateView
                    scheduleDate={activeSchedule}
                    startTime={startAt}
                    endTime={endAt}
                  />
                )}
                {(isStart || (isTimeUp && isScheduled)) && (
                  <button
                    onClick={() =>
                      onStartAssessment({
                        assessmentId,
                        slotId,
                        attemptId,
                        noOfQuestions,
                        maxTime,
                        maxAttempt,
                        instructions,
                        name,
                        attemptStatus,
                        isStartAssessment: true,
                      })
                    }
                    className="btn btn-green"
                  >
                    Start Assessment
                  </button>
                )}
                {isContinue && (
                  <button
                    onClick={() =>
                      onStartAssessment({
                        assessmentId,
                        slotId,
                        attemptId,
                        noOfQuestions,
                        maxTime,
                        maxAttempt,
                        instructions,
                        name,
                        attemptStatus,
                        isStartAssessment: false,
                      })
                    }
                    className="btn btn-blue-outline"
                  >
                    Continue Assessment
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {attempts && attempts?.length > 0 && (
        <div className="assessment-attempt-list">
          {attempts?.map((attempt, index) => {
            return (
              <AttemptItem
                key={`attempt-${attempt?.slotId}-${index}`}
                attempt={attempt}
                index={index}
              />
            );
          })}
        </div>
      )}
    </li>
  );
};
