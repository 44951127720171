import React, { useRef, useState } from "react";
import loginPpasswrodGraphics from "assets/images/login-passwrod-graphics.svg";
import signupFinishGraphics from "assets/images/signup-finish-graphics.svg";
import envelopGraphics from "assets/images/envelop-graphics.svg";
import warningIcon from "assets/images/warning-graphics.svg";
import { FormFeedback } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { AuthLayout, ModalLayout } from "shared";
import InputControl from "shared/components/InputControl";
import { useForgotPassword } from "master/hooks";
import { Link } from "react-router-dom";
import { successMessage } from "utils";

export const ForgotPassword = () => {
  const [update, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const {
    forgotPassEmail,
    setForgotPassEmail,
    forgotPasswordAuth,
    modalState,
    onCloseModal,
  } = useForgotPassword();
  const { email, isEmailSend } = forgotPassEmail;

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setForgotPassEmail((draft) => {
      draft[name] = value;
    });
  };

  const resendMail = () => {
    forgotPasswordAuth.mutate(email, {
      onSuccess: (data) => {
        successMessage(data);
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      forgotPasswordAuth.mutate(email);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <AuthLayout
      leftDivImage={isEmailSend ? signupFinishGraphics : loginPpasswrodGraphics}
    >
      {isEmailSend && (
        <div className="invitation-content">
          <img src={envelopGraphics} alt="" className="block-imgage" />
          <h2>Check your inbox for resetting your password</h2>
          <div className="invitation-text">
            <p className="form-auth-sm-txt mb-0">
              We have sent a reset password link to you at
              <span> {email}</span>
            </p>
            <div className="invite-link">
              <span
                onClick={resendMail}
                data-disabled={forgotPasswordAuth?.isPending}
                className="form-text-link"
              >
                Resend signup link
              </span>
            </div>
          </div>
        </div>
      )}
      {!isEmailSend && (
        <form>
          <h2>Forgot Your Password?</h2>
          <p className="form-auth-sm-txt">
            Just enter your email, and we'll send you a reset link.
          </p>

          <div className="mb-3">
            <label className="form-label">Enter Email Address</label>
            <InputControl
              type="email"
              name="email"
              value={email}
              className="form-control"
              onChange={onHandleChange}
              disabled={forgotPasswordAuth.isPending}
              invalid={validator.current.message(
                "email",
                email,
                "required|email"
              )}
            />
            <FormFeedback>
              {validator.current.message("email", email, "required|email")}
            </FormFeedback>
          </div>
          <button
            type="submit"
            onClick={(e) => onSubmit(e)}
            className="btn btn-primary"
            disabled={forgotPasswordAuth.isPending}
          >
            Send Reset Link
          </button>
        </form>
      )}
      {modalState.actionFrom === "invalidEmail" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={modalState?.info?.message}
          message={modalState?.info?.errors && modalState?.info?.errors[0]}
          onConfirm={onCloseModal}
          onCancel={onCloseModal}
          confirmButtonLabel={"Try Again"}
          icon={warningIcon}
          modalFooter={
            <p>
              Need help? <Link to="/support">Raise a Support Ticket</Link>
            </p>
          }
        />
      )}
    </AuthLayout>
  );
};
