import React, { useEffect } from "react";
import parse from "html-react-parser";
import {
  CourseTaskDocs,
  CourseTaskFooter,
  CourseTaskTheory,
  CourseTaskVideo,
} from ".";
import { SpinnerComponent } from "shared";
import fullscreenIcons from "assets/images/icons/fullscreen.svg";
import { Link, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { getSelectedProgram, getTokens, getUniqueDeviceId } from "utils";
import { useActivityLog } from "@nergy-ui/practicetool";
import { useCustomActivityLog } from "..";

export const CouseTaskView = ({
  title = "",
  isPending = false,
  description = "",
  taskGroup,
  taskSections,
  taskContents,
  courseType,
  currentCourseTask,
  onStartSimulation,
  updateCourseTasksStatus,
  isMobile = false,
  currentCourseTaskIndex,
  onPrevTask,
  onNextTask,
  isLastTask,
}) => {
  const { sendLogToServer } = useCustomActivityLog();
  const [pdfFilePath, setPdfFilePath] = useImmer(false);
  const isTheory = taskGroup === 1;
  const isVideo = taskGroup === 2;
  const isDocument = taskGroup === 3;
  let { courseId } = useParams();
  const selectedProgram = getSelectedProgram();

  const studentBatchId = selectedProgram?.studentBatchId;
  const courseTaskId = currentCourseTask?.courseTaskId;
  const tokens = getTokens();

  const deviceId = getUniqueDeviceId();
  const apiSetting = {
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    apiEndpoint: `api/v2/portal/activity-logs`,
    configEndpoint: `api/v2/portal/activity-logs/${studentBatchId}/config`,
    sessionEndPoint: `api/v2/portal/activity-logs/session`,
    headers: {
      Authorization: `Bearer ${tokens?.token}`,
      DeviceId: deviceId,
    },
  };

  useActivityLog({
    studentBatchId,
    courseId,
    courseTaskId,
    apiSetting,
  });

  useEffect(() => {
    return () => {
      if (isMobile) {
        const storedData = localStorage.getItem("student-session");
        const log = storedData ? JSON.parse(storedData) : [];
        if (log?.length > 0) {
          sendLogToServer.mutate({ log });
        }
      }
    };
  }, []);

  return (
    <div
      className={`col-12 single-page-content-block ${!isMobile && "col-md-8"}`}
    >
      <div className="content-wrapper">
        <div className="content-block tasks-content scrollbar-style">
          {isPending ? (
            <SpinnerComponent />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>{title}</h3>
                {isDocument && pdfFilePath && (
                  <Link
                    className="pdf-fullscreen-btn"
                    to={`/contents/${pdfFilePath}`}
                    target="_blank"
                  >
                    <img src={fullscreenIcons} alt="" />
                    <span>Full Screen Mode</span>
                  </Link>
                )}
              </div>
              {description && <>{parse(description)}</>}
              {isTheory &&
                taskSections?.length > 0 &&
                taskSections?.map((elem, index) => {
                  return (
                    <CourseTaskTheory
                      key={elem?.key}
                      title={elem?.title}
                      description={elem?.description}
                      sectionContents={elem?.sectionContents}
                      taskSections={taskSections}
                      index={index}
                    />
                  );
                })}
              {isVideo && (
                <div style={{ maxWidth: "1000px" }}>
                  {taskContents?.length > 0 &&
                    taskContents?.map((elem) => {
                      return (
                        <CourseTaskVideo
                          key={elem?.key}
                          contentType={elem?.contentType}
                          fileURL={elem?.fileURL}
                        />
                      );
                    })}
                </div>
              )}
              {isDocument &&
                taskContents?.length > 0 &&
                taskContents?.map((elem, index) => {
                  return (
                    <CourseTaskDocs
                      key={elem?.key}
                      index={index}
                      taskContents={taskContents}
                      contentType={elem?.contentType}
                      fileURL={elem?.fileURL}
                      setPdfFilePath={setPdfFilePath}
                    />
                  );
                })}
            </>
          )}
        </div>
        <CourseTaskFooter
          courseType={courseType}
          taskGroup={taskGroup}
          isPending={isPending}
          currentCourseTask={currentCourseTask}
          updateCourseTasksStatus={updateCourseTasksStatus}
          onStartSimulation={onStartSimulation}
          isMobile={isMobile}
          currentCourseTaskIndex={currentCourseTaskIndex}
          onPrevTask={onPrevTask}
          onNextTask={onNextTask}
          isLastTask={isLastTask}
        />
      </div>
    </div>
  );
};
