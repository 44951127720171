import React from "react";
import {
  NotificationDropDownSkelton,
  NotificationEmptyMessage,
  NotificationIndividualItem,
  useNotificationBanner,
} from "..";
import NotificationIcon from "assets/images/notification.svg";
import "../styles/notification.styles.css";

export const NotificationDropDown = () => {
  const {
    notificationsBanner,
    viewAllNotification,
    notificationDetails: data,
  } = useNotificationBanner({
    load: true,
    unreadOnly: false,
  });
  const { isLoading } = notificationsBanner;

  return (
    <div className={`header-card header-dropdown header-notification`}>
      {isLoading ? (
        <>
          <NotificationDropDownSkelton />
        </>
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <div className="header-card-header d-flex justify-content-between align-items-center px-4 py-3">
                <h2>Notifications</h2>
                <span onClick={viewAllNotification}>View All</span>
              </div>
              <div className="header-card-content">
                <ul className="notification-list">
                  {data &&
                    data?.slice(0, 4)?.map((notification, index) => (
                      <span className="link-decoration" key={notification?.key}>
                        <NotificationIndividualItem
                          data={notification}
                          trim={true}
                          viewAllNotification={viewAllNotification}
                        />
                      </span>
                    ))}
                </ul>
              </div>
            </>
          ) : (
            <NotificationEmptyMessage
              heading={"No notifications yet!"}
              subHeading={"When you get notifications,they'll show up here."}
              image={NotificationIcon}
            />
          )}
        </>
      )}
    </div>
  );
};
