import React, { useCallback } from "react";
import calendarIcon from "assets/images/icons/calendar-icon-dark-grey.svg";
import clockIcon from "assets/images/icons/timer-icon-dark-grey.svg";

export const AttemptItem = ({ attempt, index }) => {
  const {
    referenceNo,
    scheduledOn,
    startedAtStr,
    endedAtStr,
    startAt,
    endAt,
    assessmentMasterStatus,
  } = attempt;

  const isAbsent = assessmentMasterStatus === 8;

  const status = useCallback(() => {
    switch (assessmentMasterStatus) {
      case 0:
        return {
          color: "teal-txt",
          text: "In progress",
        };
      case 1:
        return {
          color: "green-txt",
          text: "Completed",
        };
      case 2:
        return {
          color: "orange-txt",
          text: "Invalid",
        };
      case 3:
        return {
          color: "yellow-txt",
          text: "Not started",
        };
      case 4:
        return {
          color: "green-txt",
          text: "Passed",
        };
      case 5:
        return {
          color: "red-txt",
          text: "Failed",
        };
      case 6:
        return {
          color: "blue-txt",
          text: "Awaiting result",
        };
      case 7:
        return {
          color: "blue-txt",
          text: "Not completed",
        };
      case 8:
        return {
          color: "grey-txt",
          text: "Absent",
        };
      case 9:
        return {
          color: "yellow-txt",
          text: "Upcoming",
        };
      default:
        return {
          color: "",
          text: "",
        };
    }
  }, [assessmentMasterStatus]);

  const statusIndicator = status();

  return (
    <div className="attempt-list-item">
      <div className="attempt-list-column">
        <span className="colum-label">Reference No</span>
        <span className="colum-txt">
          {!isAbsent && referenceNo ? referenceNo : "_ _"}
        </span>
      </div>
      <div className="attempt-list-column column-wide">
        <span className="colum-label">{`Attempt ${
          index + 1
        } Scheduled On`}</span>
        <div className="date-info-block">
          <div className="colum-txt-group">
            <img src={calendarIcon} alt="" />
            <span className="colum-txt">{scheduledOn}</span>
          </div>
          <div className="colum-txt-group">
            <img src={clockIcon} alt="" />
            <span className="colum-txt">{`${startAt} - ${endAt}`}</span>
          </div>
        </div>
      </div>
      <div className="attempt-list-column">
        <span className="colum-label">Started</span>
        <span className="colum-txt">
          {!isAbsent && startedAtStr ? startedAtStr : "_ _"}
        </span>
      </div>
      <div className="attempt-list-column">
        <span className="colum-label">Ended</span>
        <span className="colum-txt">
          {!isAbsent && endedAtStr ? endedAtStr : "_ _"}
        </span>
      </div>
      <div className="attempt-list-column">
        <span className="colum-label">Status</span>
        <span className={`colum-txt ${statusIndicator?.color}`}>
          {statusIndicator?.text}
        </span>
      </div>
    </div>
  );
};
