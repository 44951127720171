import { useQuery } from "@tanstack/react-query";
import { getAssessmentSlot, GET_ASSESSMENT_SLOT } from "..";

export const useAssessmentSlotQuery = ({
  isModalOpen = false,
  scheduleId = null,
}) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_SLOT, scheduleId],
    queryFn: () => getAssessmentSlot(scheduleId),
    enabled: !!scheduleId && isModalOpen,
  });
};
