import React, { useEffect, useRef, useState } from "react";
import {
  CourseTaskSideBarCard,
  StudentSpecificDataBtn,
  StudentSpecificDataList,
} from ".";
import { useImmer } from "use-immer";
import { ModalLayout } from "shared";
import StudentSpecificDataImg from "assets/images/student-specific-data-img.svg";

export const CourseTaskSideBar = ({
  name = "",
  studentTasks = [],
  currentCourseTask,
  onCourseTaskCardClick,
  onCourseCardNavigate,
  isMobile,
  studentDataDisplayName,
  modalState,
  onOpenModal,
  onCloseModal,
  studentSpecificDataQuery,
  courseType,
  displayDuration,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [studentSpecificData, setStudentSpecificData] = useImmer(null);
  const currentButtonRef = useRef(null);

  useEffect(() => {
    if (currentButtonRef.current) {
      currentButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: firstLoad ? "center" : "start",
      });
      if (firstLoad) {
        setFirstLoad(false);
      }
    }
  }, [currentButtonRef, currentCourseTask]);

  useEffect(() => {
    if (studentSpecificDataQuery?.data) {
      setStudentSpecificData(
        studentSpecificDataQuery?.data
          ? studentSpecificDataQuery.data.map((item) => {
              if (item.endsWith(",}")) {
                const element = item.replace(/,(?=[^,]*$)/, "");
                return JSON.parse(element);
              } else {
                return JSON.parse(item);
              }
            })
          : null
      );
    }
  }, [studentSpecificDataQuery?.data]);

  return (
    <div className="col-12 col-md-4 single-page-sidebar">
      <div className="sidebar-header">
        <h3>{name}</h3>
        {!!studentDataDisplayName && (
          <StudentSpecificDataBtn
            onOpenModal={onOpenModal}
            studentDataDisplayName={studentDataDisplayName}
          />
        )}
        <div className="border-top mt-3 pt-2 sidebar-sub-heading">
          Course Topics
        </div>
      </div>
      <div className="sidebar-content scrollbar-style">
        <ul className="sidebar-item-list">
          {studentTasks &&
            studentTasks?.length > 0 &&
            studentTasks?.map((element, index) => {
              const isCurrent = element?.id === currentCourseTask?.id;
              return (
                <CourseTaskSideBarCard
                  sino={index + 1}
                  key={element?.id}
                  ref={isCurrent ? currentButtonRef : null}
                  title={element?.title}
                  taskGroup={element?.taskGroup}
                  isComplete={element?.studentTaskStatus === 2}
                  courseType={courseType}
                  duration={element?.taskDuration}
                  isCurrent={
                    currentCourseTask?.courseTaskId === element?.courseTaskId
                  }
                  onClick={() => {
                    isMobile
                      ? onCourseCardNavigate(element)
                      : onCourseTaskCardClick(element);
                  }}
                  displayDuration={displayDuration}
                />
              );
            })}
        </ul>
      </div>
      {modalState?.isOpen &&
        modalState?.actionFrom === "student_specific_data" && (
          <ModalLayout
            isOpen={modalState.isOpen}
            title={studentDataDisplayName}
            iconClass={"modal-icon-big"}
            modalStyle={{ maxWidth: "450px" }}
            icon={StudentSpecificDataImg}
            modalBody={
              <StudentSpecificDataList
                studentSpecificData={studentSpecificData}
                studentSpecificDataQuery={studentSpecificDataQuery}
              />
            }
            modalButtonGroup={
              studentSpecificDataQuery?.isLoading ? (
                <></>
              ) : (
                <button
                  type="button"
                  onClick={onCloseModal}
                  className="btn btn-primary"
                >
                  Ok
                </button>
              )
            }
          />
        )}
    </div>
  );
};
