import React from "react";
import { useMutation } from "@tanstack/react-query";
import { sendActivityLog } from "..";

export const useCustomActivityLog = () => {
  const sendLogToServer = useMutation({
    mutationFn: sendActivityLog,
    onSuccess: () => {
      localStorage.removeItem("student-session");
    },
  });
  return {
    sendLogToServer,
  };
};
