import { useEffect } from "react";
import { useImmer } from "use-immer";

export const useNetworkStatus = () => {
  const [networkState, setNetworkState] = useImmer({
    since: undefined,
    isOnline: navigator.onLine,
  });

  useEffect(() => {
    const handleOnline = () => {
      setNetworkState((draft) => {
        draft.isOnline = true;
        draft.since = new Date().toString();
        return draft;
      });
    };

    const handleOffline = () => {
      setNetworkState((draft) => {
        draft.isOnline = false;
        draft.since = new Date().toString();
        return draft;
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return { ...networkState };
};
