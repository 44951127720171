import React from "react";
import { Modal, ModalBody } from "reactstrap";
import timeoutIcon from "assets/images/assessment-timeout-graphics.svg";

export const TimeoutModal = ({ timeTaken, isOpen, onSubmit, ...rest }) => {
  return (
    <Modal isOpen={isOpen} className="common-modal modal__timeout" {...rest}>
      <ModalBody>
        <div className="common-modal-content">
          <img src={timeoutIcon} alt="" className="modal-icon" />
          <h3>{`Time Taken : ${timeTaken}m`} </h3>
          <p>Your Section being timeout.</p>
          <div className="modal-action">
            <button onClick={onSubmit} className="btn btn-primary px-4">
              Review/Submit
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
