import React from "react";

export const ProfileDropDownSkelton = () => {
  return (
    <div className="header-card-content">
      <div className="user-basic-info d-flex align-items-center p-3">
        <div className="user-avatar skeleton"></div>
        <div className="user-info-text cutome-width-for-username">
          <div className="user-name-txt skeleton-text"></div>
          <div className="user-sub-txt user-email skeleton-text"></div>
        </div>
      </div>
      <div className="actions-list">
        <button className="action-btn skeleton">
          <div className="action-btn-content align-items-center">
            <div className="skeleton-icon me-1"></div>
            <div className="skeleton-text mb-0"></div>
          </div>
          <span className="btn-arrow">
            <div className="skeleton-icon"></div>
          </span>
        </button>
        <button className="action-btn skeleton">
          <div className="action-btn-content align-items-center">
            <div className="skeleton-icon me-1"></div>
            <div className="skeleton-text mb-0"></div>
          </div>
          <span className="btn-arrow">
            <div className="skeleton-icon"></div>
          </span>
        </button>
        <button className="action-btn skeleton">
          <div className="action-btn-content align-items-center">
            <div className="skeleton-icon me-1"></div>
            <div className="skeleton-text mb-0"></div>
          </div>
          <span className="btn-arrow">
            <div className="skeleton-icon"></div>
          </span>
        </button>
      </div>
    </div>
  );
};
