import React from "react";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import slotFailedImage from "assets/images/assessment-schedule-failed-graphics.svg";

export const ScheduleUnsuccessfullModal = ({
  isOpen,
  onCancel,
  onConfirm,
  data,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      className="common-modal modal__assessment_schedule"
      {...rest}
    >
      <ModalBody>
        <div className="common-modal-content">
          <img src={slotFailedImage} alt="" className="modal-icon" />
          <p className="modal-sm-txt">{data?.message}!</p>
          <p className="modal-bold-txt">
            {moment(data?.scheduledDate, "DD-MMM-YYYY").format("MMMM DD YYYY")}
          </p>
          <p className="modal-bold-txt">
            {`${data?.startAtStr} - ${data?.endAtStr}`}
          </p>
          <div className="modal-action">
            <button className="btn btn-blue" onClick={onConfirm}>
              Try again
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
