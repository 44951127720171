import React, { useEffect } from "react";
import lockIcon from "assets/images/icons/trash-icon.svg";
import { PasswordToggle } from "shared";
import InputControl from "shared/components/InputControl";
import { FormFeedback } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

export const AccountDeletionModalBody = ({
  showPassWord,
  setShowPassword,
  passwordPayload,
  setPasswordPayload,
  onSubmit,
  handleBack,
  validator,
  isPasswordError,
}) => {
  const isPasswordInvalid = isPasswordError === "Invalid password!";
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isPasswordInvalid) {
      validator.current.showMessageFor("confirmPassword");
    }
  }, [isPasswordInvalid]);

  const handleBackModal = () => {
    if (location.pathname === "/account-delete") {
      navigate("/", { replace: true });
    }
    handleBack();
  };

  return (
    <>
      <img src={lockIcon} alt="" className={"modal-icon-small"} />
      <div className="main-heading">Account Deletion</div>
      <div className="main-content-text">
        Deleting your account will permanently remove all associated data,
        including your profile, preferences, and progress made on the platform.
        This action is irreversible, and once your account is deleted, it cannot
        be recovered. All personal information and activity logs will be
        permanently erased from our systems, and access to any services linked
        to your account will be discontinued. To proceed with deleting your
        account, please confirm your password in the field below :
      </div>
      <div className="mt-2 text-start ">
        <div className="position-relative form-group has-icon-right mb-3 change_password">
          <label className="form-label" htmlFor="conf_pass">
            Confirm password
          </label>
          <InputControl
            id="conf_pass"
            className="form-control"
            placeholder="Confirm password"
            name="confirmPassword"
            type={showPassWord ? "text" : "password"}
            value={passwordPayload}
            onChange={(e) => {
              setPasswordPayload(e.target.value);
            }}
            invalid={
              validator.current.message(
                "confirmPassword",
                passwordPayload,
                "required"
              ) || isPasswordInvalid
            }
          />
          <PasswordToggle
            passwordShown={showPassWord}
            togglePassword={() => setShowPassword((draft) => !draft)}
          />
          <FormFeedback>
            {isPasswordInvalid
              ? "The password you entered is incorrect."
              : validator.current.message(
                  "confirmPassword",
                  passwordPayload,
                  "required"
                )}
          </FormFeedback>
        </div>
      </div>
      <div className="modal-action btn_password">
        <button
          type="submit"
          className="btn btn-primary px-3"
          onClick={handleBackModal}
        >
          Keep My Account
        </button>
      </div>
      <div className="mt-3 btn text-danger" onClick={onSubmit}>
        Delete My Account
      </div>
    </>
  );
};
