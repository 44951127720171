import { useAppScope } from "master";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "shared";
import { setCookie } from "utils";

export const StartingAssessment = () => {
  const { AppState } = useAppScope();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const assessmentId_q = params.get("assessmentId");
  const slotId_q = params.get("slotId");
  const attemptId_q = params.get("attemptId");
  const assessmentToken_q = params.get("assessmentToken");
  const isFromMobileApp = params.get("isFromMobileApp");

  useEffect(() => {
    if (assessmentId_q && slotId_q && assessmentToken_q && isFromMobileApp) {
      return;
    } else if (AppState.token) return navigate("/");
  }, [AppState.token]);

  useEffect(() => {
    if (assessmentId_q && slotId_q && assessmentToken_q && isFromMobileApp) {
      try {
        setCookie(
          "_stu_user_data",
          JSON.stringify({
            refreshToken: "20e5db5b66dc4",
            token: "eyJhbGciOi-HIkR4H_o",
            user: {
              userId: "126c8e",
              firstName: "",
              lastName: "",
              email: "",
            },
          })
        );
        localStorage.setItem("_stu_selectedProgram", true);
        window.location.href = `/program/assessment/start?assessmentId=${assessmentId_q}&slotId=${slotId_q}&attemptId=${attemptId_q}&assessmentToken=${assessmentToken_q}`;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return <LoadingSpinner marinHeight="0px" />;
};
