import React from "react";
import certificateBadge from "assets/images/icons/certificate-badge.svg";
import calendarIConGrey from "assets/images/icons/calendar-icon-grey.svg";
import timerIconGrey from "assets/images/icons/timer-icon-grey.svg";

export const CertificateItem = ({
  item,
  certificate = false,
  onOpenModal,
  certificateProgramName,
}) => {
  const formatDate = (value, format) => {
    const newDate = value.split(" ");
    if (format === "date") {
      return newDate[0].replaceAll("-", " ");
    } else {
      return `${newDate[1].slice(0, 5)} ${newDate[2]}`;
    }
  };

  const handleModalState = () => {
    if (certificate || item?.isGenerated) {
      const info = {
        title: item.certificateName,
        programName: item.programName
          ? item.programName
          : certificateProgramName,
        studentCertificateId: item.id,
        certificateUrl: item.url,
        certificateName: item.certificateName,
        certificateDate: item.localCreatedDate,
        certificateGuid: item.certificateGuid,
      };
      onOpenModal({ actionFrom: "openModal", info });
    }
  };

  return (
    <div className="grid-item" onClick={handleModalState}>
      <div className="grid-item-content">
        <div className="grid-item-left">
          <img src={certificateBadge} alt="" />
        </div>
        <div className="grid-item-right">
          {item.programName && (
            <span className="grid-item-badge">{item.programName}</span>
          )}
          <h2>{item.certificateName}</h2>
          {item.localCreatedDate ? (
            <div className="grid-item-footer">
              <div className="grid-footer-item">
                <img src={calendarIConGrey} alt="" />
                <span>{formatDate(item.localCreatedDate, "date")}</span>
              </div>
              <div className="grid-footer-item">
                <img src={timerIconGrey} alt="" />
                <span>{formatDate(item.localCreatedDate, "time")}</span>
              </div>
            </div>
          ) : (
            <span className="grid-footer-item">
              This Certificate will be available only after the completion of
              the course.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
