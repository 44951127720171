import { useQuery } from "@tanstack/react-query";
import { getAssessmentSchedule, GET_ASSESSMENT_SCHEDULE } from "..";

export const useAssessmentScheduleQuery = ({
  assessmentId = null,
  studentBatchId = null,
  isModalOpen = false,
}) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_SCHEDULE, assessmentId, studentBatchId],
    queryFn: () => getAssessmentSchedule({ assessmentId, studentBatchId }),
    enabled: !!assessmentId && !!studentBatchId && isModalOpen,
  });
};
