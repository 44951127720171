import React from "react";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import scheduleImage from "assets/images/assessment-date-confirm-graphics.svg";

export const ScheduleConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  data,
  ...rest
}) => {
  const { scheduledDate, startAtStr, endAtStr } = data;

  return (
    <Modal
      isOpen={isOpen}
      className="common-modal modal__assessment_schedule"
      {...rest}
    >
      <ModalBody>
        <div className="common-modal-content">
          <img src={scheduleImage} alt="" className="modal-icon" />
          <p className="modal-sm-txt">
            Please confirm your assessment date and time !
          </p>
          <p className="modal-bold-txt">
            {moment(scheduledDate, "DD-MMM-YYYY").format("MMMM DD YYYY")}
          </p>
          <p className="modal-bold-txt">{`${startAtStr} - ${endAtStr}`}</p>
          <div className="modal-action">
            <button className="btn btn-blue-outline" onClick={onCancel}>
              Back
            </button>
            <button className="btn btn-blue" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
