import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "react-datetime/css/react-datetime.css";

import {
  ResetPassword,
  ForgotPassword,
  Login,
  SupportTicket,
  GeneratePassword,
  AppScope,
  Home,
  SessionExpired,
  External,
  PageViewTracker,
  SignUp,
} from ".";
import { LoadingSpinner, ProtectedRoute } from "shared";
import {
  Faq,
  PDFViewer,
  Privacy,
  StartingAssessment,
  TermsAndConditions,
} from "modules";
import { app, analytics } from "./config"; // for google analytics -> Do not remove

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const Master = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppScope>
        <BrowserRouter>
          <PageViewTracker />
          <Routes>
            <Route exact element={<Login />} path={"/login"} />
            <Route exact element={<SupportTicket />} path={"/support"} />
            <Route
              exact
              element={<ForgotPassword />}
              path={"/forgotpassword"}
            />
            <Route exact element={<ResetPassword />} path={"/auth/reset"} />
            <Route exact element={<SignUp />} path={"/signup"} />
            <Route
              exact
              element={<GeneratePassword />}
              path={"/auth/signup/complete"}
            />
            <Route
              exact
              element={<LoadingSpinner marinHeight="0px" />}
              path={"/starting-simulation"}
            />
            <Route
              exact
              element={<StartingAssessment />}
              path={"/starting-assessment"}
            />
            <Route
              exact
              element={
                <ProtectedRoute redirectTo={"/session-expired"}>
                  <Home />
                </ProtectedRoute>
              }
              path={"/*"}
            />
            <Route
              exact
              element={<SessionExpired />}
              path={"/session-expired"}
            />
            <Route element={<PDFViewer />} path={"/contents/:dir/:file"} />
            <Route element={<Privacy />} path={"/privacy"} />
            <Route element={<Faq />} path={"/faq"} />
            <Route element={<TermsAndConditions />} path={"/terms"} />
            <Route element={<External />} path={"/external"} />
            {/* <Route element={<RefundPolicy />} path={"/refund-policy"} />
            <Route element={<ContactUs />} path={"/contact-us"} /> */}
          </Routes>
        </BrowserRouter>
      </AppScope>
    </QueryClientProvider>
  );
};
