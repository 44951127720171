import { Axios, getAssessmentToken } from "utils";

const assessmentToken = getAssessmentToken();

export const generateAssessmentToken = async (payload) => {
  const { assessmentId, slotId, refreshToken } = payload;
  const res = await Axios.get(
    `/v2/portal/Assessments/${assessmentId}/generate-assessmentToken/${slotId}?refreshToken=${refreshToken}`
  );
  return res.data;
};

export const getQuestionnaire = async ({
  assessmentId,
  slotId,
  attemptId,
  assessmentTokenState,
}) => {
  const questionnaireParameters =
    !!attemptId && attemptId !== "null"
      ? `AssessmentId=${assessmentId}&SlotId=${slotId}&AttemptId=${attemptId}`
      : `AssessmentId=${assessmentId}&SlotId=${slotId}`;
  const res = await Axios.get(
    `/v2/portal/assessments/${assessmentId}/start?${questionnaireParameters}`,
    {
      headers: {
        "Exam-Token": assessmentToken ? assessmentToken : assessmentTokenState,
      },
    }
  );

  return res.data;
};

export const answerAssessment = async (payload) => {
  const res = await Axios.post(
    `/v2/portal/assessments/${payload.assessmentMasterId}/answer`,
    payload,
    {
      headers: {
        "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
      },
    }
  );
  return res.data;
};

export const deleteAnswer = async (answerId) => {
  const res = await Axios.delete(`/v2/portal/assessments/answer/${answerId}`, {
    headers: {
      "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
    },
  });
  return res.data;
};

export const submitAnswers = async ({ assessmentMasterId, payload }) => {
  const res = await Axios.put(
    `/v2/portal/assessments/${assessmentMasterId}/end`,
    payload,
    {
      headers: {
        "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
      },
    }
  );
  return res.data;
};

export const bulkSubmitAnswers = async ({ assessmentMasterId, payload }) => {
  const res = await Axios.put(
    `/v2/portal/assessments/${assessmentMasterId}/answers`,
    payload,
    {
      headers: {
        "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
      },
    }
  );
  return res.data;
};
