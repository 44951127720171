import React, { useEffect, useRef } from "react";

const extractVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

export const YouTubePlayer = ({ videoUrl }) => {
  const playerRef = useRef(null);
  const videoId = extractVideoId(videoUrl);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      const script = document.createElement("script");
      script.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    };

    if (!window.YT) {
      loadYouTubeAPI();
    } else if (window.YT && window.YT.Player) {
      createPlayer();
    }

    window.onYouTubeIframeAPIReady = () => {
      createPlayer();
    };

    function createPlayer() {
      const player = new window.YT.Player(playerRef.current, {
        height: "390",
        width: "640",
        videoId: videoId,
        playerVars: {
          rel: 0,
        },
      });
    }
  }, [videoId]);

  return (
    <div>
      <div ref={playerRef} className="responsive-iframe"></div>
    </div>
  );
};
