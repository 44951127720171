import PdfIcon from "assets/images/icons/pdf-icon.svg";

export const PdfContent = ({ title, redirectTo }) => {
  return (
    <a
      className="attachement-btn"
      href={`/contents/${redirectTo}`}
      target="_blank"
      rel="noreferrer"
    >
      <img src={PdfIcon} alt="" />
      <span className="attachement-btn-txt">{title}</span>
    </a>
  );
};
