import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuestionareContext } from "master";
import { trimText } from "utils";
import { useImmer } from "use-immer";

export const MainHeader = ({ children }) => {
  const location = useLocation();

  const isResult = location.pathname === "/program/assessment/result";
  const isQuestionnaire =
    location.pathname === "/program/assessment/start" ||
    location.pathname === "/program/assessment/review" ||
    location.pathname === "/program/assessment/allQuestions";

  const [isResultPage, setIsResultPage] = useImmer(isResult);
  const [isQuestionnairePage, setIsQuestionnairePage] =
    useImmer(isQuestionnaire);

  const questionnaireContext = useQuestionareContext();
  const { questionnaire } = !!questionnaireContext && questionnaireContext;

  const assessmentTitle = questionnaire ? questionnaire?.name : null;

  useEffect(() => {
    setIsResultPage(isResult);
    setIsQuestionnairePage(isQuestionnaire);
  }, [location.pathname]);

  const brandLogo = process.env.REACT_APP_MAIN_LOGO;

  return (
    <header className="auth-header">
      <div className="container">
        <div className="row">
          <div className="col-12 header-block d-flex align-items-center justify-content-between">
            <div className="header-left-block d-flex align-items-end">
              {isQuestionnairePage || isResultPage ? (
                <span className="brand-logo">
                  <img src={brandLogo} alt="Nergy Vidya" />
                </span>
              ) : (
                <Link className="brand-logo" to="/">
                  <img src={brandLogo} alt="Nergy Vidya" />
                </Link>
              )}
              {assessmentTitle && isQuestionnairePage && (
                <h3 className="question-title mb-0 ms-4">
                  {trimText(assessmentTitle, 40)}
                </h3>
              )}
            </div>

            <div className="header-actions d-flex justify-content-end align-items-center">
              {children}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
