import React, { useEffect, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useQuestionnaire,
  NetworkStatusMessage,
  useCountdown,
  START_ASSESSMENT,
} from "..";
import {
  HelpModal,
  TimeoutModal,
  InstructionsModal,
  TokenExpiredModal,
} from "modules";
import { useImmer } from "use-immer";
import { getTokens } from "utils";
import { useQueryClient } from "@tanstack/react-query";

export const QuestionnaireContext = createContext();

export const QuestionnaireScope = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isNetworkMessage, setIsNetworkMessage] = useImmer(false);
  const [systemTime, setSystemTime] = useImmer(Date.now());
  const [isNetworkMessageOpen, setIsNetworkMessageOpen] = useImmer(false);
  const getRefreshToken = getTokens();
  const queryClient = useQueryClient();

  const isQuestionnaire =
    location.pathname === "/program/assessment/start" ||
    location.pathname === "/program/assessment/review" ||
    location.pathname === "/program/assessment/allQuestions";

  const allQuestionsPages =
    isQuestionnaire || location.pathname === "/program/assessment/result";

  const {
    isModalOpen,
    setIsModalOpen,
    instruction,
    help,
    onToggleInstruction,
    onToggleHelp,
    questionnaire,
    isOnline,
    createAssessmentToken,
    startAssessmentQuery,
    onTimeOut,
    ...rest
  } = useQuestionnaire();

  const validTill =
    startAssessmentQuery?.data?.validTill || questionnaire?.validTill;

  const currentTime =
    startAssessmentQuery?.data?.currentSystemTime ||
    questionnaire?.currentSystemTime;

  const { timer } = useCountdown({
    validTill,
    currentTime,
  });
  const { hours, minutes, seconds } = timer;

  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      onTimeOut();
    }
  }, [hours, minutes, seconds]);

  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();

    if (allQuestionsPages && isNetworkMessage) {
      window.addEventListener("contextmenu", disableRightClick);
    }
    return () => window.removeEventListener("contextmenu", disableRightClick);
  }, [allQuestionsPages, isNetworkMessage]);

  useEffect(() => {
    if (isOnline) {
      setIsNetworkMessageOpen(true);

      setTimeout(() => {
        setIsNetworkMessage(false);
        setIsNetworkMessageOpen(false);
      }, 3000);
    } else {
      setIsNetworkMessage(true);
      setIsNetworkMessageOpen(true);
    }
  }, [isOnline]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const currentTime = Date.now();
      const timeDifference = currentTime - systemTime;
      setSystemTime((draft) => {
        draft = Date.now();
        return draft;
      });
      if (!document.hidden && timeDifference > 3000) {
        queryClient.invalidateQueries([START_ASSESSMENT]);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [systemTime]);

  const getAssementToken = () => {
    const assessmentId = sessionStorage.getItem("assessmentId") || "";
    const slotId = sessionStorage.getItem("slotId") || "";

    createAssessmentToken.mutate({
      assessmentId,
      slotId,
      refreshToken: getRefreshToken.refreshToken,
    });
  };

  const proceedAssessment = () => {
    onToggleInstruction(null, "header");
    getAssementToken();
  };

  const onSubmit = () => {
    setIsModalOpen((draft) => {
      draft.isOpen = false;
      draft.type = "";
      return draft;
    });
    navigate("/program/assessment/review", { replace: true });
  };

  const onTokenExpiredClose = () => {
    setIsModalOpen((draft) => {
      draft.isOpen = false;
      draft.type = "";
      draft.message = "";
      return draft;
    });
    window.close();
  };

  const onContinueAssessment = () => {
    setIsNetworkMessageOpen(false);
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        instruction,
        help,
        onToggleInstruction,
        onToggleHelp,
        questionnaire,
        startAssessmentQuery,
        isOnline,
        hours,
        minutes,
        seconds,
        ...rest,
      }}
    >
      {allQuestionsPages &&
        isNetworkMessage &&
        !isOnline &&
        !isNetworkMessageOpen && (
          <div className="network-status-badge">
            <span className="badge-dot"></span>
            <span>Offline</span>
          </div>
        )}

      {isNetworkMessageOpen && allQuestionsPages && isNetworkMessage && (
        <NetworkStatusMessage
          isOnline={isOnline}
          onSubmit={onContinueAssessment}
        />
      )}
      {props.children}
      {isModalOpen.isOpen && isModalOpen.type === "help" && (
        <HelpModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "help"}
          onCancel={() => onToggleHelp()}
          data={help}
        />
      )}
      {isModalOpen.isOpen && isModalOpen.type === "instruction" && (
        <InstructionsModal
          isOpen={isModalOpen.isOpen && isModalOpen.type === "instruction"}
          onCancel={() => {
            onToggleInstruction(null, "header");
          }}
          onSubmit={proceedAssessment}
          data={instruction}
          actionFrom={isModalOpen.from}
        />
      )}
      {isModalOpen.type === "timeOut" &&
        isModalOpen.isOpen &&
        isQuestionnaire && (
          <TimeoutModal
            isOpen={isModalOpen.type === "timeOut" && isModalOpen.isOpen}
            onSubmit={onSubmit}
            timeTaken={questionnaire?.maxTime}
          />
        )}
      {isModalOpen.type === "tokenExpired" &&
        isModalOpen.isOpen &&
        allQuestionsPages && (
          <TokenExpiredModal
            isOpen={isModalOpen.type === "tokenExpired" && isModalOpen.isOpen}
            message={isModalOpen?.message}
            onSubmit={onTokenExpiredClose}
          />
        )}
    </QuestionnaireContext.Provider>
  );
};
