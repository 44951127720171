import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { strongPassword } from "utils";
import {
  AccountDeletionConfirmModalBody,
  AccountDeletionModalBody,
  ChangePasswordModalBody,
} from ".";
import { useAccountDeletion, usePassword } from "..";
import "../styles/changePassword.styles.css";
import { Modal } from "reactstrap";
import { useImmer } from "use-immer";
import { useLocation } from "react-router-dom";

export const ChangePassword = ({ openChangePassword, onCloseModal }) => {
  const [update, forceUpdate] = useState();
  const [tab, setTab] = useImmer("changePassword");
  const location = useLocation();

  useEffect(() => {
    setTab(
      location.pathname === "/account-delete"
        ? "accountDeletion"
        : "changePassword"
    );
  }, [location.pathname]);

  const {
    handleBack,
    showPassWord,
    handlePassword,
    changePasswordAuth,
    changePasswordPayload,
    setChangePasswordPayload,
  } = usePassword({ onCloseModal });

  const {
    password,
    showPassword,
    isPasswordError,
    deleteAccountMutation,
    showDeleteConformation,
    setPassword,
    setShowPassword,
    setShowDeleteConformation,
  } = useAccountDeletion();

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  const validator2 = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (tab === "changePassword") {
      if (validator.current.allValid()) {
        changePasswordAuth.mutate(changePasswordPayload);
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } else {
      if (validator2.current.allValid()) {
        setShowDeleteConformation(true);
      } else {
        validator2.current.showMessages();
        forceUpdate(1);
      }
    }
  };

  const onDelete = () => {
    deleteAccountMutation.mutate(password);
  };

  return (
    <Modal
      isOpen={openChangePassword}
      centered
      className={`common-modal`}
      style={{ maxWidth: "430px" }}
    >
      {!showDeleteConformation && (
        <div className="row w-100 p-0 m-0">
          <div
            className={`col-6 modal-tab ${
              tab === "changePassword" ? "active" : ""
            }`}
            onClick={() => setTab("changePassword")}
          >
            Change Password
          </div>
          <div
            className={`col-6 modal-tab ${
              tab === "accountDeletion" ? "active" : ""
            }`}
            onClick={() => setTab("accountDeletion")}
          >
            Account Deletion
          </div>
        </div>
      )}
      <div
        className={`modal-body common-modal-content ${
          tab === "changePassword" ? "" : "px-4"
        }`}
      >
        {tab === "changePassword" ? (
          <ChangePasswordModalBody
            validator={validator}
            forceUpdate={forceUpdate}
            showPassWord={showPassWord}
            handlePassword={handlePassword}
            changePasswordPayload={changePasswordPayload}
            setChangePasswordPayload={setChangePasswordPayload}
            handleBack={handleBack}
            onSubmit={onSubmit}
          />
        ) : !!showDeleteConformation ? (
          <AccountDeletionConfirmModalBody
            handleBack={handleBack}
            onDelete={onDelete}
          />
        ) : (
          <AccountDeletionModalBody
            validator={validator2}
            passwordPayload={password}
            showPassWord={showPassword}
            isPasswordError={isPasswordError}
            onSubmit={onSubmit}
            handleBack={handleBack}
            setPasswordPayload={setPassword}
            setShowPassword={setShowPassword}
          />
        )}
      </div>
    </Modal>
  );
};
