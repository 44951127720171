import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useImmer } from "use-immer";
import {
  GET_MY_PROGRAMS,
  activateBatch,
  acceptTerms,
  useProgramListQuery,
} from "..";
import { useModal } from "shared";
import { errorFormatter } from "utils";
import { useNavigate } from "react-router-dom";

export const useDashboard = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [program, setProgram] = useImmer(false);
  const [regNo, setRegNo] = useImmer();
  const [currentProgram, setCurrentProgram] = useImmer({
    id: "",
    studentBatchId: "",
    name: "",
    displayDuration: null,
  });
  const { modalState, onOpenModal, onCloseModal, setModalState } = useModal();

  useEffect(() => {
    localStorage.removeItem("selectedProgram");
    sessionStorage.removeItem("courseGroup");
    sessionStorage.removeItem("courseList");
  }, []);

  const getAllPrgramInfo = useProgramListQuery();
  const isProgramLoading = getAllPrgramInfo.isPending;

  useEffect(() => {
    if (getAllPrgramInfo?.data && getAllPrgramInfo?.data?.length > 0) {
      setProgram((draft) => {
        draft = getAllPrgramInfo.data;
        return draft;
      });
    }
  }, [getAllPrgramInfo.data]);

  const activateBatchMutation = useMutation({
    mutationFn: activateBatch,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GET_MY_PROGRAMS]);
      onCloseModal();
      setRegNo(null);
      onOpenModal({
        actionFrom: "registrationSuccess",
      });
    },
    onError: () => {
      setModalState((draft) => {
        draft.isError = true;
        return draft;
      });
    },
  });

  const acceptTerm = useMutation({
    mutationFn: acceptTerms,
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([GET_MY_PROGRAMS]);
      onCloseModal();
      localStorage.setItem(
        "_stu_selectedProgram",
        JSON.stringify(currentProgram)
      );
      navigate("/program");
    },
  });

  return {
    program,
    isProgramLoading,
    modalState,
    onOpenModal,
    onCloseModal,
    activateBatchMutation,
    acceptTerm,
    setCurrentProgram,
    regNo,
    setRegNo,
  };
};
