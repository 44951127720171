import { useMutation } from "@tanstack/react-query";
import { newUserSignUp } from "..";
import { useNavigate } from "react-router-dom";
import { errorFormatter, isPositiveNumber, successMessage } from "utils";
import { useImmer } from "use-immer";

export const useSignUp = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useImmer({
    firstName: "",
    lastName: "",
    gender: 2,
    mobile: "",
    dateOfBirth: null,
    password: "",
    confirmPassword: "",
  });
  const [passwordShown, setPasswordShown] = useImmer({
    newPassword: false,
    confirmPassword: false,
  });

  const options = [
    {
      label: "Male",
      value: 0,
    },
    {
      label: "Female",
      value: 1,
    },
    {
      label: "Not Specified",
      value: 2,
    },
  ];

  const createNewUser = useMutation({
    mutationFn: newUserSignUp,
    onSuccess: () => {
      successMessage();
      navigate("/login", { replace: true });
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((draft) => {
      if (name === "mobile") {
        draft[name] = isPositiveNumber({ value, isAcceptZero: true });
      } else {
        draft[name] = value;
      }
      return draft;
    });
  };

  const togglePassword = (type) => {
    setPasswordShown((draft) => {
      draft[type] = !draft[type];
      return draft;
    });
  };

  return {
    createNewUser,
    userDetails,
    setUserDetails,
    passwordShown,
    setPasswordShown,
    onHandleChange,
    options,
    togglePassword,
  };
};
