import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { courseTaskTypeFinder, trimText } from "utils";

export const CourseTaskSideBarCard = forwardRef((props, ref) => {
  const {
    sino,
    title,
    taskGroup,
    isComplete,
    isCurrent,
    onClick,
    courseType,
    duration,
    displayDuration,
  } = props;
  const isAssignment = courseType === 2;
  const showDuration = isAssignment && displayDuration;

  return (
    <li
      ref={ref}
      className={`sidebar-item 
      ${isComplete ? "completed" : ""} 
      ${isCurrent ? "current" : ""}`}
      onClick={onClick}
    >
      <Link>
        <div className="index-span">{sino}</div>
        <div className="item-content">
          <div className="md-text">{trimText(title)}</div>
          <span className="sm-text">
            {courseTaskTypeFinder(taskGroup, false)}{" "}
            {showDuration && `• Duration:${duration}`}
          </span>
        </div>
      </Link>
    </li>
  );
});
