import { useImmer } from "use-immer";
import { useStudentBatchCertificateQuery } from ".";
import { trimText } from "utils";
import { useModal } from "shared";
import { useEffect } from "react";

export const useCertificate = ({
  load = false,
  studentBatchId,
  isAllCertificate,
}) => {
  const [selectedProgram, setSelectedProgram] = useImmer("");
  const [clickedTimes, setClickedTimes] = useImmer(0);

  const { modalState, onOpenModal, onCloseModal } = useModal();
  const studentBatchCertificate = useStudentBatchCertificateQuery({
    load: load,
    studentBatchId,
    programId: selectedProgram,
    isAllCertificate,
  });

  useEffect(() => {
    if (clickedTimes === 3) {
      onOpenModal({
        actionFrom: "NoCertificateAssigned",
        info: {
          message:
            "If you believe you have completed the course and your certificate is missing, please contact support for assistance",
        },
      });
    }
  }, [clickedTimes]);

  const downloadPdf = async (url = "", certificateName) => {
    if (url) {
      let c_name = trimText(certificateName, 30, "");
      const fileName = c_name.split(" ").join("_");
      try {
        const response = await fetch(url);
        if (response) {
          const blob = await response.blob();
          const fileUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = `${fileName}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(fileUrl);
        }
      } catch (e) {
        console.error("Error downloading the file:", e);
      }
    }
  };

  return {
    clickedTimes,
    setClickedTimes,
    downloadPdf,
    studentBatchCertificate,
    selectedProgram,
    setSelectedProgram,
    modalState,
    onOpenModal,
    onCloseModal,
  };
};
