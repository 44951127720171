import React, { useCallback, useRef, useState } from "react";
import { AuthLayout } from "shared";
import SupportTicketGraphics from "assets/images/support-ticket-graphics.svg";
import SimpleReactValidator from "simple-react-validator";
import { useSupportTicket } from "..";
import { FormFeedback } from "reactstrap";
import InputControl from "shared/components/InputControl";
import ControlledTextarea from "shared/components/ControlledTextarea";
import { Link } from "react-router-dom";

export const SupportTicket = () => {
  const [update, forceUpdate] = useState();

  const { ticketValues, setTicketValues, supportTickets } = useSupportTicket();

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        mobile: {
          message: "Phone number must be 10 digits.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
              ) && params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
        },
      },
    })
  );

  const onHandleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (
        (name === "registrationId" || name === "name" || name === "email") &&
        value.length > 50
      )
        return;
      setTicketValues((draft) => {
        draft[name] = value;
        return draft;
      });
    },
    [ticketValues]
  );

  const onHandleTextarea = useCallback(
    (e) => {
      const { name, value } = e;
      setTicketValues((draft) => {
        draft[name] = value;
        return draft;
      });
    },
    [ticketValues]
  );

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      const { name, registrationId, email, phonenumber, ...rest } =
        ticketValues;
      const subjectContent = `My name is ${name} ${
        !!registrationId ? `(registration ID - ${registrationId}) ` : ""
      }and my contact details  - Email: ${email} & Phonenumber: ${
        phonenumber ? phonenumber : "N/A"
      }`;
      supportTickets.mutate({
        ...rest,
        subject: subjectContent,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <AuthLayout leftDivImage={SupportTicketGraphics}>
      <form className="pb-3">
        <h3 className="mb-2 mt-2">Support Ticket</h3>
        <p className="form-auth-sm-txt mb-2">Enter Your Details And Remarks</p>
        <div className="mb-2">
          <label className="form-label">Enter your name*</label>
          <InputControl
            type="text"
            className="form-control"
            name="name"
            value={ticketValues.name}
            onChange={onHandleChange}
            invalid={validator.current.message(
              "name",
              ticketValues.name,
              "required"
            )}
          />
          <FormFeedback>
            {validator.current.message("name", ticketValues.name, "required")}
          </FormFeedback>
        </div>
        <div className="mb-2">
          <label className="form-label">Enter your email Id*</label>
          <InputControl
            type="text"
            className="form-control"
            name="email"
            value={ticketValues.email}
            onChange={onHandleChange}
            invalid={validator.current.message(
              "email",
              ticketValues.email,
              "required|email"
            )}
          />
          <FormFeedback>
            {validator.current.message(
              "email",
              ticketValues.email,
              "required|email"
            )}
          </FormFeedback>
        </div>
        <div className="mb-2">
          <label className="form-label">Enter your phone number</label>
          <InputControl
            type="text"
            className="form-control"
            name="phonenumber"
            value={ticketValues.phonenumber}
            onChange={onHandleChange}
            invalid={validator.current.message(
              "phonenumber",
              ticketValues.phonenumber,
              "numeric|mobile"
            )}
          />
          <FormFeedback>
            {validator.current.message(
              "phonenumber",
              ticketValues.phonenumber,
              "numeric|mobile"
            )}
          </FormFeedback>
        </div>
        <div className="mb-2">
          <label className="form-label">Enter your register id</label>
          <InputControl
            type="text"
            className="form-control"
            name="registrationId"
            value={ticketValues.registrationId}
            onChange={onHandleChange}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Enter your message*</label>
          <ControlledTextarea
            name="message"
            value={ticketValues.message}
            rows="3"
            limit={150}
            ref={validator}
            isValidationRequired={true}
            onChange={onHandleTextarea}
          />
        </div>
        <div className="button-group d-flex justify-content-center align-items-center">
          <Link to="/login" className="btn btn-secondary">
            Back
          </Link>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={supportTickets.isPending}
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};
