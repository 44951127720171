import { Axios, getUniqueDeviceId, queryBuilder } from "utils";

export const getCourseTaskGroup = async ({ studentBatchId, type }) => {
  const res = await Axios.get(
    `v2/portal/Programs/batches/${studentBatchId}/courses/${type}/group-details`
  );
  return res.data;
};

export const getCourse = async ({ studentBatchId, type, courseGroupId }) => {
  const queryString = queryBuilder({
    courseGroupId,
  });
  const res = await Axios.get(
    `/v2/portal/Programs/batches/${studentBatchId}/courses/${type}?${queryString}`
  );
  return res.data;
};

export const getCourseTaskList = async (
  programId,
  courseId,
  studentBatchId
) => {
  const res = await Axios.get(
    `v2/portal/Programs/${programId}/batches/${studentBatchId}/courses/${courseId}/Tasks`
  );
  return res.data;
};

export const getCourseTaskDetails = async (courseTaskId) => {
  const res = await Axios.get(`v2/portal/CourseTasks/${courseTaskId}/details`);
  return res.data;
};

export const generateSimulationToken = async ({ taskId, refreshToken }) => {
  const res = await Axios.get(
    `/CourseTasks/GenerateSimulationToken/${taskId}?refreshToken=${refreshToken}`
  );
  return res.data;
};

export const updateCourseTaskStatus = async ({ studentTaskId, taskStatus }) => {
  const res = await Axios.put(
    `/v2/portal/courseTasks/${studentTaskId}/update/${taskStatus}`
  );
  return res.data;
};

export const getStudentSpecificData = async (studentBatchId, courseId) => {
  const res = await Axios.get(
    `/v2/portal/Programs/batches/${studentBatchId}/courses/${courseId}/student-specific-data`
  );
  return res.data;
};

export const sendActivityLog = async ({ log, isLogOut = false }) => {
  const deviceId = getUniqueDeviceId();
  let url = "/v2/portal/activity-logs";
  if (isLogOut) {
    url += "?isLogOut=true";
  }
  const res = await Axios.post(url, log, {
    headers: {
      DeviceId: deviceId,
    },
  });
  return res.data;
};

export const getActivityLogSession = async () => {
  const deviceId = getUniqueDeviceId();
  const res = await Axios.get(`v2/portal/activity-logs/session`, {
    headers: {
      DeviceId: deviceId,
    },
  });
  return res.data;
};
