import { Axios } from "utils";

export const authenticate = async (auth) => {
  const res = await Axios.post("/v2/accounts/token", auth);
  return res.data;
};

export const getRefreshToken = async (payload) => {
  const res = await Axios.post(`/v2/accounts/token/refresh`, payload);
  return res.data;
};

export const IsUserExist = async (emailId) => {
  const email = encodeURIComponent(emailId);
  const res = await Axios.get(`/v2/accounts/${email}/validate `);
  return res.data;
};

export const supportTicket = async (message) => {
  const res = await Axios.post("/SupportTickets", message);
  return res.data;
};

export const supportTicketForCertificate = async (data) => {
  const url = `/v2/portal/Students/batches/${data.studentBatchId}/ticket`;
  const res = await Axios.post(url, data.message);
  return res.data;
};

export const userSignOut = async () => {
  const res = await Axios.post("/v2/accounts/sign-out");
};

export const getForgotPassword = async (email) => {
  const res = await Axios.get(`/v2/accounts/forgot-password`, {
    params: {
      emailAddress: email,
    },
  });
  return res.data;
};

export const resetPassword = async (auth) => {
  const res = await Axios.post("/v2/accounts/forgot-password", auth);
  return res.data;
};

export const createPassword = async (auth) => {
  const res = await Axios.post("/v2/accounts/signup/complete", auth);
  return res.data;
};

export const resendInvitationEmail = async (email) => {
  const res = await Axios.post(
    `/v2/accounts/signup/start`,
    {},
    {
      params: {
        emailAddress: email,
      },
    }
  );
  return res.data;
};

export const signUpToken = async (emailAddress) => {
  const res = await Axios.post(
    `/v2/Accounts/signup/token?emailAddress=${emailAddress}`,
    {}
  );
  return res.data;
};

export const newUserSignUp = async (payload) => {
  const res = await Axios.post(`v2/Accounts/signup`, payload);
  return res.data;
};
