export const AssessmentStatusRow = ({ icon, label = "" }) => {
  return (
    <div className="assessment-status-row">
      <div className="status-item">
        {icon && <img src={icon} alt="" />}
        <span>{label}</span>
      </div>
    </div>
  );
};
